<template>
  <div>
    <PopupLanguage
      v-model="isPopupDuplicateActive"
      :on-click-btn="handlerDuplicateContent"
      name="property-asset-project-create"
    />
    <div 
      id="data-list-list-view" 
      class="data-list-container">
      <custom-table
        ref="table"
        :max-items="itemsPerPage"
        :data="items"
        :searchKeys="searchKeys"
        pagination
        search
      >
        <div 
          slot="header" 
          class="flex items-center flex-grow justify-between">
          <AddNewContent name="vdo-project-create" />

          <!-- ITEMS PER PAGE -->
          <LhDropdownPerPage 
            :items="items" 
            :items-per-page="itemsPerPage" 
            :current-page="currentPage" 
            :queried-items="queriedItems"
            @click="(page) => itemsPerPage = page"
          /> 
        </div>

        <template slot="thead">
          <vs-th sort-key="id">Id</vs-th>
          <vs-th>Thumbnail</vs-th>
          <vs-th sort-key="title">Title</vs-th>
          <vs-th sort-key="description">Description</vs-th>
          <vs-th>Show</vs-th>
          <vs-th sort-key="published_date">Published Date</vs-th>
          <vs-th sort-key="updated_at">Updated at</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, indextr) in data" 
              :data="tr" 
              :key="indextr">
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.id }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <img
                  v-if="getThumbnail(tr)"
                  :src="getThumbnail(tr)" 
                  alt="img" 
                  height="110" >
              </td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.title }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.description }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.show_homepage == true ? 'แสดง' : 'ซ่อน' }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <p class="brand-priority">{{ publishDateFormat(tr.published_date) }}</p>
              </td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <p class="brand-priority">{{ updatedAtDatetimeFormat(tr.updated_at) }}</p>
              </td>
              <vs-td class="whitespace-no-wrap cursor-default">
                <div class="flex space-x-2">
                  <feather-icon
                    class="cursor-pointer"
                    v-if="!tr.is_default"
                    icon="CopyIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="duplicateContent(tr.id)"
                  />
                  <router-link
                    :to="{ name: `${routePrefix}-edit`, params: { id: tr.id }}"
                    class="pt-2 color-text-link"
                  >
                    <feather-icon
                      icon="EditIcon"
                      svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    />
                  </router-link>
                  <feather-icon
                    class="cursor-pointer"
                    v-if="!tr.is_default"
                    icon="TrashIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    @click.stop="deleteData(tr.uuid)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
// import get from 'lodash/get'
import { computed } from '@vue/composition-api'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import AddNewContent from '@/components/AddNewContent'
import PopupLanguage from '@/components/PopupLanguage'
import useProjectVdoUtil from '@/use/useProjectVdoUtil'
import omit from 'lodash/omit'
import get from 'lodash/get'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import CustomTable from '@/components/CustomTable'

export default {
  name: 'VdoListView',
  components: { PopupLanguage, AddNewContent, LhDropdownPerPage, CustomTable },
  setup(props, ctx) {
    const crudFunction = useCrud(ctx, 'projectVdo')
    const { moment, updatedAtDatetimeFormat } = useDatepicker()
    const { formData, prepareFormData } = useProjectVdoUtil(ctx)

    crudFunction.routePrefix.value = 'vdo-project'

    const items = computed(() => {
      return crudFunction.result.value
    })

    const handlerDuplicateContentFromCRUD = async (
      formData,
      prepareFormData,
      langToDuplicationContent,
    ) => {
      ctx.root.$vs.loading()
      await ctx.root.$store.dispatch('locale/changeLang', langToDuplicationContent)
      const result = await ctx.root.$store.dispatch(
        'projectVdo/fetchOne',
        crudFunction.contentDuplicateId.value,
      )
      await prepareFormData(result)
      const activeUser = ctx.root.$store.state.AppActiveUser
      const email = get(activeUser, 'email', '')
      const created_by = email
      const updated_by = email
    

      const resultDuplicate = await ctx.root.$store.dispatch('projectVdo/addItem',
        {
          ...omit(formData, ['uuid', 'id', 'updated_at', 'created_at']),
          created_by,
          updated_by
        }
      )
      crudFunction.isPopupDuplicateActive.value = false
      await ctx.root.$vs.loading.close()
      await ctx.root.$router.push({
        name: `vdo-project-edit`,
        params: {
          id: resultDuplicate.id,
        },
      })
    }

    const handlerDuplicateContent = (langToDuplicationContent) => {
      handlerDuplicateContentFromCRUD(formData, prepareFormData, langToDuplicationContent)
    }

    const publishDateFormat = (value) => {
      return moment(value).format('DD MMM YYYY')
    }

    const getThumbnail = (data) => {
      return get(data, ['video_thumbnail', 0, 'url'], null)
    }

    return {
      ...crudFunction,
      items,
      handlerDuplicateContent,
      publishDateFormat,
      updatedAtDatetimeFormat,
      searchKeys: ['title', 'description'],
      getThumbnail
    }
  },
}
</script>

<style scoped></style>
